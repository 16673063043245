import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import withAnimation from '../withAnimation'

const HomeCare = ({ value, onPrimary, name, handleChange, submitHomeCare, fadeOutThen }) => {
  const onSubmit = event => {
    event.preventDefault()
    fadeOutThen(() => submitHomeCare())
  }

  return (
    <form onSubmit={onSubmit}>
      {onPrimary && <h1 className='light-font'>When you’re making decisions about at home care, what’s most important to you?</h1>}
      {!onPrimary && <h1 className='light-font'>When you’re making decisions about {name}'s at home care, what’s most important to you?</h1>}
      <TextareaAutosize name='homeCare' onChange={handleChange} value={value} autoFocus />
      <button type='submit'>Next</button>
    </form>
  )
}

export default withAnimation(HomeCare)
