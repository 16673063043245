import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import withAnimation from '../withAnimation'

const Conditions = ({ value, onPrimary, name, handleChange, submitConditions, fadeOutThen }) => {
  const onSubmit = event => {
    event.preventDefault()
    fadeOutThen(() => submitConditions())
  }

  return (
    <form onSubmit={onSubmit}>
      {onPrimary && <h1 className='light-font'>Please describe any health conditions or experiences that impact your body.</h1>}
      {!onPrimary && <h1 className='light-font'>Please describe any health conditions or experiences that impact {name}'s body.</h1>}
      <p className='sub_copy'>Past health events, ongoing conditions or sensitivities,&nbsp;pregnancy&nbsp;plans,&nbsp;etc.</p>
      <TextareaAutosize name='conditions' onChange={handleChange} value={value} autoFocus />
      <button type='submit'>Next</button>
    </form>
  )
}

export default withAnimation(Conditions)
