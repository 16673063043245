import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import withAnimation from '../withAnimation'

const MoreInfo = ({ value, onPrimary, name, handleChange, submitMoreInfo, fadeOutThen }) => {
  const onSubmit = event => {
    event.preventDefault()
    fadeOutThen(() => submitMoreInfo())
  }

  return (
    <form onSubmit={onSubmit}>
      {onPrimary && <h1 className='light-font'>Anything else you want us to know?</h1>}
      {!onPrimary && <h1 className='light-font'>Anything else you want us to know about {name}?</h1>}
      <TextareaAutosize name='moreInfo' onChange={handleChange} value={value} autoFocus />
      <button type='submit'>Next</button>
    </form>
  )
}

export default withAnimation(MoreInfo)
