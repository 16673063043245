import React from 'react'
import { useMask } from 'react-mask-field'
import withAnimation from '../withAnimation'

const Birthday = ({ value, onPrimary, name, error, handleChange, submitBirthday, fadeOutThen }) => {
  const onSubmit = (event) => {
    event.preventDefault()
    fadeOutThen(() => submitBirthday(value))
  }

  const ref = useMask({ mask: '__/__/____', replacement: { _: /\d/ } })

  console.log('bday', value)
  return (
    <form onSubmit={onSubmit}>
      {!onPrimary && <h1 className='light-font'>When's {name}'s birthday?</h1>}
      {onPrimary && <h1 className='light-font'>When's your birthday?</h1>}

      <p className='sub_copy'>Please enter date in mm/dd/yyyy format.</p>
      <div>
        <input ref={ref} className='reduced_width' type='birthdate' name='birthdate' onChange={handleChange} value={value} autoFocus />
      </div>

      {error && <small className='field-error'>{error}</small>}

      <button type='submit' disabled={error}>Next</button>
    </form>
  )
}

export default withAnimation(Birthday)
