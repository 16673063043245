import React from 'react'

const MessageView = ({ message }) => (
  <div className='container m-0 md:m-8 bg-white md:rounded-lg md:drop-shadow-lg'>
    <h1 className='text-center m-4'>{message.title}</h1>
    <div dangerouslySetInnerHTML={{ __html: message.body }} />
  </div>
)

export default MessageView
