import React from 'react'

import withAnimation from '../withAnimation'

const Sex = ({ setFieldValue, onPrimary, name, submitSex, transitioning, fadeOutThen }) => {
  const record = event => {
    const { target } = event
    const value = target.value

    if (value && value.length > 0) {
      setFieldValue('sex', value.trim())
      fadeOutThen(() => submitSex())
    }
  }

  const buttons = [
    {
      label: 'Female',
      value: 'female'
    },
    {
      label: 'Male',
      value: 'male'
    },
    {
      label: "It's Complicated",
      value: 'complicated'
    }
  ]

  const renderButtons = ({ label, value }) => <button key={value} className='btn_option' type='button' value={value} disabled={transitioning} onClick={record}>{label}</button>

  return (
    <form>
      {onPrimary && <h1 className='light-font'>What's your physical sex?</h1>}
      {!onPrimary && <>
        <h1 className='light-font'>What's {name}'s physical sex?</h1>
        <p className='sub_copy'>This question is about their body. Pronouns are next.</p>
      </>}
      <div style={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column', marginTop: '30px' }}>
        {buttons.map(renderButtons)}
      </div>
    </form>
  )
}

export default withAnimation(Sex)
