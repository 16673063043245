import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import queryString from 'query-string'

import withAnimation from './withAnimation'

import { context as AuthContext } from '../context/auth'

const FormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email'),
  password: Yup.string().required()
})

const Login = ({ location, history, fadeOutThen }) => {
  const { login } = useContext(AuthContext)
  const [success, setSuccess] = useState()

  const { values, errors, touched, handleChange, handleSubmit, setErrors } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: FormValidationSchema,
    onSubmit: async (values) => {
      try {
        await login(values.email, values.password)
        // show login successful message
        // redirect to inbox
        setSuccess(true)

        const { then } = queryString.parse(location.search)

        const redirect = `/${then}` || '/inbox'
        fadeOutThen(() => history.push(redirect))
      } catch (err) {
        console.log('login error', err)
        setErrors({ email: 'Username or Password is invalid' })
        // handle the error (show incorrect username/passoword message)
      }
    }
  })

  const passwordReset = () => {
    history.push('/password-reset')
  }

  return (
    <form onSubmit={handleSubmit}>
      {success && <div>Login Successful...heading to your inbox</div>}
      <h1>Welcome back.<br />Let's sign in.</h1>
      <br />
      <h3 className='light-font'>Enter your email</h3>
      <input
        className='reduced_width'
        name='email'
        onChange={handleChange} // this looks odd but has purpose...formik marks fields "touched" on blur
        value={values.email}
        autofocus
      />
      {errors.email && touched.email && <small className='field-error'>{errors.email}</small>}
      <h3 className='light-font' style={{ marginTop: '40px' }}>...and your password.</h3>
      <input
        className='reduced_width'
        type='password'
        name='password'
        onChange={handleChange} // this looks odd but has purpose...formik marks fields "touched" on blur
        value={values.password}
      />
      {errors.password && touched.password && <small className='field-error'>{errors.password}</small>}
      <button type='submit'>Log In</button>
      <p><a href='#' onClick={passwordReset}>Forgot your password?</a></p>
      <p>We'll help you reset it so you can get back in.</p>
    </form>
  )
}

export default withAnimation(Login)
