import React from 'react'

import Crescent from '../../images/logo_crescent.svg'
import Freckles from '../../images/logo_text.svg'
import Hamburger from '../../images/hamburger.svg'

const InboxHeader = () => {
  return (
    <div className='w-full flex justify-between items-center'>
      <div className='m-4'>&nbsp;</div>
      <div className='flex items-center justify-center'>
        <img src={Crescent} />
        <img src={Freckles} />
      </div>
      <div className='m-4'>
        <img src={Hamburger} />
      </div>
    </div>
  )
}

export default InboxHeader
