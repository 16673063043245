import React from 'react'
import withAnimation from '../withAnimation'

const Done = ({ name = 'Sarah', onPrimary, submitDone, setFieldValue, fadeOutThen }) => {
  const submitDoneHandler = event => {
    event.preventDefault()
    setFieldValue('done', 'done')
    fadeOutThen(() => submitDone())
  }

  const submitCreateHandler = event => {
    event.preventDefault()
    setFieldValue('done', 'new')
    fadeOutThen(() => submitDone())
  }

  return (
    <>
      <h1 className='light-font'>Great work!</h1>
      {onPrimary && 
        <p className='sub_copy'>Your profile is complete.  We can now customize the science with you in mind!<br />
          Do you manage at-home health care decisions for others?  As a paid subscriber, you can create multiple profiles, so we can customize the science not just for you, but for you loved ones as well.
        </p>
      }
      {!onPrimary && 
        <p className='sub_copy'>{name}'s profile is complete. We can now customize the science with {name} in mind.</p>
      }
      <div>
        <button onClick={submitCreateHandler}>Create another profile</button>
        <button onClick={submitDoneHandler}>Take me to my library</button>
      </div>
    </>
  )
}

export default withAnimation(Done)
