import React from 'react'

import withAnimation from '../withAnimation'

const QuickNote = ({ completeStep, fadeOutThen }) => (
  <>
    <h1 class='quick-note-h1'>Quick note about privacy and personalization:</h1>
    <p className='sub_copy'>We only ask for information that is relevant to Explainer content. All fields are optional, but the more context we have, the better personalization we can provide, including catching safety concerns specific to your age, sex, prescriptions or health history. As always, your information is private, encrypted and secure. Our full data practices and values are outlined in our privacy policy.</p>
    <button type='button' onClick={() => fadeOutThen(completeStep)}>Sounds Good</button>
  </>
)

export default withAnimation(QuickNote)
