import { Link } from 'react-router-dom'
import Footer from './components/Footer'

const About = () => {
	return (
		<>
			<div className="about-inner">
				<div className="inner_margin">
          <h1>Hi. We’re Freckles.</h1> 
          <p>We’re a health startup on a mission to help people make better healthcare decisions by explaining and personalizing the science. We like to think we blend the ambition of Silicon Valley with the hustle and heart of a midwestern, family-owned small business. Freckles was founded in 2020 and is based in Milwaukee, WI.</p>

          <hr class="freckles_gradient"/>

          <h2>WE BELIEVE THAT BUSINESS CAN BE A FORCE FOR GOOD</h2>

          <p><span>We're devoted to unbiased, evidence-based science.</span> All of the information we publish is created and verified by our network of doctors, pharmacists and other health professionals, and based on the most current, credible research</p>
          <p><span>We work for our readers, not for advertisers.</span> Unlike most of the internet, advertisers are NOT part of the picture here—period. We’re skeptical of “free” health content that is funded by ad and data sales, crowded with sponsored content, or written for the sake of earning web traffic, rather than based on benefit to the user. Freckles readers are at the center of what we do and how we do it. No compromises.</p>
          <p><span>We believe in privacy.</span> We’re committed to the strictest standards of data privacy and security. And your info is yours—we won’t sell or share your data. Ever.</p>
          <p><span>We’re committed to information literacy.</span> We hope to be a resource you can rely on, but also want to empower you to assess studies and news stories on your own. Freckles prioritizes education in all of our content, and will continue to build tools to help our readers become better self-advocates.</p>
          <p><span>We don’t want money to get in the way.</span> For every Freckles membership purchased, we will donate a membership to someone who can’t afford it, by partnering with community health and social services organizations.</p>
          <p><span>We understand that money isn’t the only barrier.</span> To further our mission of better health information for everyone, Freckles is building partnerships with organizations that focus on health literacy and patient education.</p>
          <p><span>Do you want the same world we do?</span></p>

          <button className="freckles_btn"><Link to='/register' className=''>Subscribe</Link></button>

          <hr class="freckles_gradient"/>

          <h2>Join Our Team</h2>

          <p>Have a skillset you think would benefit a health startup on a mission to make health information better? We’re always looking to connect with healthcare professionals, researchers, writers, designers, engineers, public health + community health advocates, and health-related organizations. Reach out to <a href="mailto:info@freackleshealth.com" className="green_cta">info@freackleshealth.com</a> or find<br/> <a href="https://www.linkedin.com/in/johnoliverrose" target="_blank" className="green_cta">John on LinkedIn.</a></p>
        </div>  
      </div>

			<Footer />
		</>
	)
}

export default About