import React, { useContext } from 'react'
import _map from 'lodash.map'
import { context as AuthContext } from '../../context/auth'

import useInbox from '../../hooks/useInbox'

import InboxListItem from './InboxListItem'

const Inbox = () => {
  const { user } = useContext(AuthContext)
  const { inbox: items } = useInbox(user)

  console.log('inbox items: ', items)
  return (
    <div className='container w-full h-full m-0 md:m-8 bg-white md:rounded-lg md:drop-shadow-lg'>
      <h1 className='text-center m-4'>Inbox</h1>
      {_map(items, item => <InboxListItem key={`inbox_key_${item.id}`} item={item} />)}

    </div>
  )
}

export default Inbox
