import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useHistory } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

const FormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(11, '12 characters minimum')
    .max(128, '128 character maximum')
    .required('Password is required')
})

const NewPassword = ({ oobCode }) => {
  const [saving, setSaving] = useState(false)
  const [email, setEmail] = useState()
  const [error, setError] = useState()
  const history = useHistory()

  useEffect(() => {
    // I don't like this but something with the routing broke the simple "autofocus"
    document.getElementById('register_password_field').focus()

    firebase.auth().verifyPasswordResetCode(oobCode).then((email) => {
      setEmail(email)
    }).catch((error) => {
      console.log(error)
      setError('Reset token expired')
    })
  }, [oobCode])

  const setNewPassword = (code, email, newPassword) => {
    firebase.auth().confirmPasswordReset(code, newPassword).then(async (resp) => {
      await firebase.auth().signInWithEmailAndPassword(email, newPassword)
      history.push('/inbox')
    }).catch((error) => {
      console.log(error)
      setError(JSON.stringify(error))
      setSaving(false)
    })
  }

  const INPUT_FONT_SIZE = '30px'
  const { errors, values, handleChange, touched, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema: FormValidationSchema,
    onSubmit: (values) => {
      setSaving(true)
      setNewPassword(oobCode, email, values.password)
    }
  })

  const submitEnabled = !errors.confirm_password && !errors.password && touched.confirm_password && touched.password

  return (
    <form>
      <h1 className='light-font'>Pick a great new password</h1>
      <p className='sub_copy'><em>12 characters minimum</em></p>
      <input
        id='register_password_field'
        className='reduced_width'
        type='password'
        name='password'
        style={{ fontSize: INPUT_FONT_SIZE }}
        onChange={(e) => { handleChange(e); handleBlur(e) }} // this looks odd but has purpose...formik marks fields "touched" on blur
        onBlur={handleBlur}
        value={values.password}
        autofocus
      />
      {errors.password && touched.password && <small className='field-error'>{errors.password}</small>}
      <h1 className='light-font' style={{ marginTop: '40px' }}>Confirm that password</h1>
      <input
        className='reduced_width'
        type='password'
        name='confirm_password'
        style={{ fontSize: INPUT_FONT_SIZE }}
        onChange={(e) => { handleChange(e); handleBlur(e) }} // this looks odd but has purpose...formik marks fields "touched" on blur
        onBlur={handleBlur}
        value={values.password_confirm}
      />
      {errors.confirm_password && touched.confirm_password && <small class='field-error'>Does not match</small>}
      {error && <small class='field-error'>{error}</small>}
      <button onClick={handleSubmit} disabled={!submitEnabled || saving}>Next</button>
    </form>
  )
}

export default NewPassword
