import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'

const withAnimation = (WrappedComponent, className = 'freckles_slide') => {
  return (props) => {
    const [transition, setTransition] = useState({ opacity: 1 }) // 0

    useEffect(() => {
      setTransition({ opacity: 1 })
      return () => {
        setTransition({ opacity: 1 }) // 0
      }
    }, [className])

    const fadeOutThen = (thenFn) => {
      setTransition({ opacity: 1 }) // 0
      setTimeout(() => {
        thenFn()
      }, 100) // 1000
    }

    const styles = useSpring(transition)

    return (
      <animated.div className={className} style={styles}>
        <WrappedComponent {...props} fadeOutThen={fadeOutThen} />
      </animated.div>
    )
  }
}

export default withAnimation
