import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import _pick from 'lodash.pick'

const {
  REACT_APP_FB_API_KEY,
  REACT_APP_FB_AUTH_DOMAIN,
  REACT_APP_FB_PROJECT_ID,
  REACT_APP_FB_STORAGE_BUCK,
  REACT_APP_FB_MESS_SENDER_ID,
  REACT_APP_FB_APP_ID,
  REACT_APP_FB_MEAS_ID,
  REACT_APP_ENV
} = process.env

const firebaseConfig = {
  apiKey: REACT_APP_FB_API_KEY,
  authDomain: REACT_APP_FB_AUTH_DOMAIN,
  projectId: REACT_APP_FB_PROJECT_ID,
  storageBucket: REACT_APP_FB_STORAGE_BUCK,
  messagingSenderId: REACT_APP_FB_MESS_SENDER_ID,
  appId: REACT_APP_FB_APP_ID,
  measurementId: REACT_APP_FB_MEAS_ID
}

// Initialize Firebase App
const firebaseAppInit = firebase.initializeApp(firebaseConfig)
const db = firebase.firestore(firebaseAppInit)

const api = {
  checkEmailOptions: (email) => {
    return firebase.auth().fetchSignInMethodsForEmail(email)
  },
  register: (email = '', password = '') => {
    return firebase.auth()
      .createUserWithEmailAndPassword(email, password)
  },
  getPrimaryProfile: async (user) => {
    const { uid } = user
    const collectionName = `user_profile_private/${uid}/profiles`
    const data = await db.collection(collectionName)
      .get('isPrimary == true')

    const profiles = []

    data.forEach(doc => {
      profiles.push(doc.data())
    })

    return profiles[0]
  },
  // getProfiles: async (user) => {
  //   const { uid } = user
  //   const collectionName = `user_profile_private/${uid}/profiles`
  //   const data = await db.collection(collectionName).get()
  //   const profiles = []

  //   data.forEach(doc => {
  //     profiles.push(doc.data())
  //   })

  //   return profiles
  // },
  addProfile: async (user = {}, data = {}) => {
    if (REACT_APP_ENV !== 'TESTING') {
      const { uid } = user
      const collectionName = `user_profile_private/${uid}/profiles`

      const profileData = _pick(data, [
        'birthdate',
        'conditions',
        'homeCare',
        'isPrimary',
        'medications',
        'pronouns',
        'sex',
        'profileName'
      ])

      try {
        await db.collection(collectionName)
          .add(profileData)
        return { status: 'success', uid, data }
      } catch (error) {
        console.error(error)
        return { status: 'error', error }
      }
    }
  },
  completeRegistration: async (user) => {
    console.log('user in registration complete', user)
    const { uid } = user
    await db.collection('user_profile_private').doc(uid).set({ registrationComplete: true })
    // alert('sent registration complete')
  },
  markInboxItemViewed: async (itemId) => {
    const markInboxItemViewedFn = firebase.functions()
      .httpsCallable('markInboxItemViewed')

    try {
      await markInboxItemViewedFn({ id: itemId })
    } catch (err) {
      console.log(err)
    }
  },
  generateCustomBillingId: async () => {
    const paypalStartTransactionFn = firebase.functions()
      .httpsCallable('paypalStartTransaction')

    try {
      const { data: { customId } } = await paypalStartTransactionFn()
      return customId
    } catch (err) {
      console.log(err)
      return undefined
    }
  },
  completePaypalTransaction: async (data) => {
    // NO
    // const paypalCompleteTransactionFn = firebase.functions().httpsCallable('paypalCompleteTransaction')
    // await paypalCompleteTransactionFn(data)

    // listen to user_subscription for active status
    // or just trust paypal response at this point and check later
  }
}

export default api
