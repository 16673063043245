// https://freckles-5a237.firebaseapp.com/email-action?mode=verifyEmail&oobCode=6CezXG9EY7isDP59V3x3f5cz78II1JpE2DhuGXRxJYcAAAGA-MTY2w&apiKey=AIzaSyC9r7DQ8TJ8cJxu-b14La11mgOvH-Tp8mg&lang=en

import React from 'react'
import useQueryParams from '../../hooks/useQueryParams'

import VerifyEmail from './VerifyEmail'
import NewPassword from './NewPassword'

const ACTIONS = {
  VERIFY_EMAIL: 'verifyEmail',
  RESET_PASSWORD: 'resetPassword',
  RECOVER_EMAIL: 'recoverEmail'
}

const EmailAction = () => {
  const { mode, oobCode } = useQueryParams()
  console.log('mode', mode)

  switch (mode) {
    case ACTIONS.VERIFY_EMAIL:
      return <VerifyEmail oobCode={oobCode} />
    case ACTIONS.RESET_PASSWORD:
      return <NewPassword oobCode={oobCode} />
    default:
      return <div>Error - action unknown</div>
  }
}

export default EmailAction
