import React, { useCallback } from 'react'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'

import crescent from '../../images/logo_crescent.svg'

const firestoreToDisplayDate = (publishedAt) => {
  return publishedAt ? format(new Date(publishedAt.seconds * 1000), 'MMM d') : '...'
}

const LeDot = ({ isNew }) => (
  <div>
    <div className={`w-3.5 h-3.5 ${isNew && 'bg-green-400 border-2 border-white dark:border-gray-800 rounded-full'}`} />
  </div>
)

export default ({ item }) => {
  const history = useHistory()

  const handleItemClick = useCallback(() => {
    switch (item.type) {
      case 'explainer':
      case 'message':
        history.push(`/inbox/${item.id}`)
        break
      default:
        // do nothing if type is explainerPreview
    }
  }, [item])

  const isPreviewItem = item.type === 'explainerPreview'
  const textClass = isPreviewItem ? 'text-gray-400' : 'text-gray-800'
  const titleClass = isPreviewItem ? 'text-gray-400' : 'text-fviolet'
  const showHover = !isPreviewItem ? 'cursor-pointer hover:bg-gray-100' : ''
  const isNew = !item.viewedAt && !isPreviewItem

  return (
    <div className={`grid grid-cols-12 mb-4 ${showHover}`} onClick={handleItemClick}>
      <div className='col-san-2 flex items-center'>
        <LeDot isNew={isNew} />
        <img src={crescent} alt='explainer-icon' />
      </div>
      <div className='col-span-9'>
        <div className={`text-2xl ${titleClass}`}>{item.subject}</div>
        <div className={textClass}>{item.description}</div>
      </div>
      <div className={`m-1 col-span-1 ${textClass}`}>{isPreviewItem ? '' : firestoreToDisplayDate(item.publishedAt)}</div>
    </div>
  )
}
