import React from 'react'
import withAnimation from '../withAnimation'

const Welcome = ({ completeStep, fadeOutThen }) => (
  <>
    <h1>Welcome!</h1>
    <p className='sub_copy'>We’re excited to help you on your health&nbsp;journey.<br />Let’s get you set up.</p>
    <button type='button' onClick={() => fadeOutThen(completeStep)}>Get Started!</button>
  </>
)

export default withAnimation(Welcome)
