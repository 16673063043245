import React from 'react'

import withAnimation from '../withAnimation'

const ProfileName = ({ value, onPrimary, handleChange, submitName, fadeOutThen, error }) => {
  const onSubmit = event => {
    event.preventDefault()
    fadeOutThen(() => submitName())
  }

  return (
    <form onSubmit={onSubmit}>
      {onPrimary && <h1 className='light-font'>Profile Name</h1>}
      {!onPrimary && (
        <>
          <h1 className='light-font'>Who is the person you are caring for?</h1>
          <p className='sub_copy'>This will be the name on the profile.</p>
        </>
      )}
      <input
        className='reduced_width'
        name='profileName'
        onChange={handleChange} // this looks odd but has purpose...formik marks fields "touched" on blur
        value={value}
        autofocus
      />
      {error && <small className='field-error'>{error}</small>}
      <button disabled={error} type='submit'>Next</button>
    </form>
  )
}

export default withAnimation(ProfileName)
