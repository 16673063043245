import React, { useContext, useCallback, useState, useEffect } from 'react'
import withAnimation from '../withAnimation'
import API from '../../api'
import { context as AuthContext } from '../../context/auth'

const ERROR_CODES = {
  EMAIL_EXISTS: 'auth/email-already-in-use',
  PASSWORD_TOO_WEAK: 'auth/weak-password',
  INVALID_EMAIL: 'auth/invalid-email', // this shouldn't happen (enforced by formik)
  NOT_ALLOWED: 'auth/operation-not-allowed' // this shouldn't happen (only if firebase settings for auth change)
}

const Password = ({ values, errors, touched, handleChange, handleBlur, saveUser, submitPassword, fadeOutThen }) => {
  const [error, setError] = useState()
  const [submitting, setSubmitting] = useState(false)
  const { sendVerifyEmail } = useContext(AuthContext)

  useEffect(() => {
    // I don't like this but something with the routing broke the simple "autofocus"
    document.getElementById('register_password_field').focus()
  }, [])

  const INPUT_FONT_SIZE = '30px'

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    // register first, handle errors
    setSubmitting(true)
    API.register(values.email, values.password)
      .then(async (newUser) => {
        console.log('register complete')
        saveUser(newUser)
        await sendVerifyEmail()
        fadeOutThen(() => submitPassword(newUser))
      })
      .catch((error) => {
        console.log('error in register', error)
        setError(error.code)
        setSubmitting(false)
      })
  }, [values])

  const submitEnabled = submitting || (!errors.confirm_password && !errors.password && touched.confirm_password && touched.password)

  return (
    <form>
      <h1 className='light-font'>Pick a great password</h1>
      <p className='sub_copy'><em>Minimum 12 characters</em></p>
      <input
        id='register_password_field'
        className='reduced_width'
        type='password'
        name='password'
        style={{ fontSize: INPUT_FONT_SIZE }}
        onChange={handleChange} // this looks odd but has purpose...formik marks fields "touched" on blur
        onBlur={handleBlur}
        value={values.password}
        autofocus
      />
      {errors.password && touched.password && <small className='field-error'>{errors.password}</small>}
      <h1 className='light-font' style={{ marginTop: '40px' }}>Confirm that password</h1>
      <input
        className='reduced_width'
        type='password'
        name='confirm_password'
        style={{ fontSize: INPUT_FONT_SIZE }}
        onChange={handleChange} // this looks odd but has purpose...formik marks fields "touched" on blur
        onBlur={handleBlur}
        value={values.password_confirm}
      />
      {errors.confirm_password && touched.confirm_password && <small class='field-error'>Does not match</small>}
      {error && <small class='field-error'>{error}</small>}
      <button onClick={onSubmit} disabled={!submitEnabled}>Next</button>
    </form>
  )
}

export default withAnimation(Password)
