import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Footer from './components/Footer'

const Explainer = () => {
  const sliderSettings = {
    autoplay: true,
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const slides = [
    {
      title: 'John Jacobson, MD, MACP',
      subtitle: 'Professor of Medicine University of Washington School of Medicine',
      copy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis fermentum justo, mollis vehicula libero venenatis quis. Aenean accumsan vehicula magna, sit amet ultricies metus gravida eu.'
    },
    {
      title: 'Dave Davidson, MD, MACP',
      subtitle: 'Professor of Medicine University of Washington School of Medicine',
      copy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis fermentum justo, mollis vehicula libero venenatis quis. Aenean accumsan vehicula magna, sit amet ultricies metus gravida eu.'
    },
    {
      title: 'Berry Allen, MD, MACP',
      subtitle: 'Professor of Medicine University of Washington School of Medicine',
      copy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis fermentum justo, mollis vehicula libero venenatis quis. Aenean accumsan vehicula magna, sit amet ultricies metus gravida eu.'
    },
    {
      title: 'John Jacobson, MD, MACP',
      subtitle: 'Professor of Medicine University of Washington School of Medicine',
      copy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis fermentum justo, mollis vehicula libero venenatis quis. Aenean accumsan vehicula magna, sit amet ultricies metus gravida eu.'
    },
    {
      title: 'Michael Myers, MD, MACP',
      subtitle: 'Professor of Medicine University of Washington School of Medicine',
      copy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis fermentum justo, mollis vehicula libero venenatis quis. Aenean accumsan vehicula magna, sit amet ultricies metus gravida eu.'
    }
  ]

  return (
    <>
      <div className='explainer-inner'>
        <h1>The Freckles Explainer</h1>
        <h2>Monthly health guides. Straight to your inbox.</h2>

        <div className='inner_margin'>
          <p><span>Each month, the Explainer unpacks a common health</span> issue that can be treated at home, from sunburn to insomnia, coughs to constipation. Then we go deep, breaking down treatment guidelines, studies, and health headlines to explain what the science does—and doesn’t—say.</p>
          <p><span>Upgrade to a paid membership for personalized analysis</span> that makes YOU part of the equation. Our experts can securely factor in things like your age, health history and medications, to help you understand not just what the science says—but what it means for YOU: what’s safe, what’s not, and what works. Managing care for children or other loved ones? You can add them to your membership too.</p>
          <button className='freckles_btn'><Link to='/register' className=''>Subscribe</Link></button>

          <h1>Powered by Medical Experts</h1>
          <p>The Freckles Explainer is developed by medical experts from across the country, whose only priority is helping our readers be more educated and informed about their healthcare.</p>
          <p>All content is verified by our network Freckles Research Partners—doctors, pharmacists, academics and other health professionals. We are currently working with over ### experts from leading institutions across the U.S.</p>

          <h2>Meet a few of our partners:</h2>

          <section id='slider'>
            <Slider {...sliderSettings}>
              {slides.map(({ title, subtitle, copy }) =>
                <div key={title}>
                  <div className='slide-content'>
                    <h2>{title}</h2>
                    <h4 className='has-black-color'>{subtitle}</h4>
                    <p>{copy}</p>
                  </div>
                </div>
              )}
            </Slider>
          </section>

          <h2 style={{ marginBottom: 0 }}>Want to hear more from smart folks like this?</h2>
          <button className='freckles_btn'><Link to='/about' className=''>Get The Explainer</Link></button>

          <p><span>We work a little differently.</span> The Freckles Explainer is created with readers, not advertisers, in mind. We’re serious about evidence-based science, user privacy, and information literacy.</p>
          <Link to='/explainer' className='green_cta'>Get To Know Us</Link>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Explainer
