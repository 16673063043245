import React from 'react'

import withAnimation from '../withAnimation'

const Welcome = ({ completeStep, fadeOutThen }) => (
  <>
    <h1>You're In!</h1>
    <p className='sub_copy'>As a paid Freckles subscriber, you get a personalized take on the science of health care each month.  For us to do that, we need to know a little bit more about you, a.k.a. build out your Health Profile.  You can repeate this process for additional people you care for - but let's start with you.</p>
    <button type='button' onClick={() => fadeOutThen(completeStep)}>Create profile</button>
  </>
)

export default withAnimation(Welcome)
