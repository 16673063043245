import { useEffect, useState } from 'react'

const useQueryParams = () => {
  const [params, setParams] = useState({})

  useEffect(() => {
    let match
    const pl = /\+/g // Regex for replacing addition symbol with a space
    const search = /([^&=]+)=?([^&]*)/g
    const decode = function (s) { return decodeURIComponent(s.replace(pl, ' ')) }
    const query = window.location.search.substring(1)

    const urlParams = {}
      while (match = search.exec(query)) { urlParams[decode(match[1])] = decode(match[2]) } // eslint-disable-line
    setParams(urlParams)
  }, [])

  return params
}

export default useQueryParams
