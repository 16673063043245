import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import InboxHeader from './Header'
import InboxList from './InboxList'
import InboxItem from './InboxItem'

const Inbox = () => {
  return (
    <div>
      <InboxHeader />
      <Switch>
        <Route exact path='/inbox' render={(props) => <InboxList />} />
        <Route exact path='/inbox/:id' render={(props) => <InboxItem />} />
      </Switch>
    </div>
  )
}

export default withRouter(Inbox)
