import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { context as AuthContext } from '../../context/auth'

const VerifyEmail = ({ oobCode }) => {
  const { applyActionCode, sendVerifyEmail } = useContext(AuthContext)
  const [method, setMethod] = useState('verify')
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState()
  const history = useHistory()

  useEffect(() => {
    let clearTimeoutHandle
    applyActionCode(oobCode)
      .then(resp => {
        setSuccess(true)
        setLoading(false)
        clearTimeoutHandle = setTimeout(() => {
          history.push({
            pathname: '/survey',
            search: '?then=survey'
          })
        }, 3000)
      })
      .catch(err => {
        console.log(err)
        setSuccess(false)
        setLoading(false)
      })
    return () => {
      clearTimeout(clearTimeoutHandle)
    }
  }, [])

  const handleRetryClick = () => {
    setLoading(true)
    setMethod('resend')
    setSuccess()
    sendVerifyEmail()
      .then(resp => {
        setSuccess(true)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setSuccess(false)
        setLoading(false)
      })
  }

  const handleContinueClick = () => {
    history.push({
      pathname: '/survey',
      search: '?then=survey'
    })
  }

  if (method === 'verify') {
    if (loading) {
      return <div className='explainer'>Verifying email....</div>
    }

    if (success) {
      return <div className='explainer'>Success! you should be redirected in a few seconds  <a href='#' onClick={handleContinueClick}>or click here to go now</a></div>
    } else {
      return <div className='explainer'>Email verification failed  <a href='#' onClick={handleRetryClick}>click here to resend</a></div>
    }
  }
  if (method === 'resend') {
    if (loading) {
      return <div className='explainer'>re-sending email verification</div>
    }

    if (success) {
      return <div className='explainer'>Check your email...we sent it!</div>
    } else {
      return <div className='explainer'>something bad happened...try again? <a href='#' onClick={handleRetryClick}>click here to resend</a></div>
    }
  }
  return <div className='explainer'>Error...this shouldn't happen</div>
}

export default VerifyEmail
