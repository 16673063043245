import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'

let inboxUnsubscribe

const useInboxHook = (user) => {
  const [inbox, setInbox] = useState([])
  useEffect(() => {
    // this establishes a webhook and will "push" real-time changes to the client
    if (user) {
      inboxUnsubscribe = firebase.firestore()
        .collection(`user_inbox/${user.uid}/items`)
        .onSnapshot(inboxSnapshot => {
          if (inboxSnapshot) {
            const inboxItems = []
            inboxSnapshot.forEach((doc) => {
              inboxItems.push({ id: doc.id, ...doc.data() }) // add the document id to each object
            })
            console.log('hook items', inboxItems)
            setInbox(inboxItems.sort((a, b) => a.publishedAt.seconds < b.publishedAt.seconds ? 1 : -1))
          }
        })
    }

    return () => {
      if (inboxUnsubscribe) {
        inboxUnsubscribe()
      }
    }
  }, [user])

  return {
    inbox
  }
}

export default useInboxHook
