import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import api from '../api'

const useInboxItemHook = (user, id) => {
  const [item, setItem] = useState([])

  useEffect(() => {
    const loadItem = async () => {
    // get the item
      const doc = await firebase.firestore()
        .doc(`user_inbox/${user.uid}/items/${id}`)
        .get()

      setItem(doc.data())

      // mark the item read
      await api.markInboxItemViewed(id)
    }
    loadItem()
  }, [user, id])

  return {
    item
  }
}

export default useInboxItemHook
