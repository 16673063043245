import React, { useContext } from 'react'
import _map from 'lodash.map'
import { context as AuthContext } from '../context/auth'
import useUserProfiles from '../hooks/useUserProfiles'

const ProfilesSample = ({ history }) => {
  const { user } = useContext(AuthContext)
  const { profiles, isLoading } = useUserProfiles(user)

  if (isLoading) {
    return <div>Loading profiles...</div>
  }

  return (
    <div>
      {_map(profiles, profile => {
        return <div>Primary: {profile.isPrimary}, Birthdate: {profile.birthdate}, Sex: {profile.sex} </div>
      })}
    </div>
  )
}

export default ProfilesSample
