import React, { useContext } from 'react'
import styled from 'styled-components'
import { context as AuthContext } from '../context/auth'

const UserInfoContainer = styled.div`
    max-width: 800px;
`

const UserExample = ({ history }) => {
  const { user, subscription, logout } = useContext(AuthContext)
  return (
    <UserInfoContainer>
      <div>User: {JSON.stringify(user || {})}</div>
      <div>Subscription: {JSON.stringify(subscription || {})}</div>
      {user && <button onClick={() => logout()}>Log Out</button>}
      {!user && <button onClick={() => history.push('/login')}>Log In</button>}
    </UserInfoContainer>
  )
}

export default UserExample
