import { Link } from 'react-router-dom'
import logoCrescent from './images/logo_crescent.svg'
import signature from './images/signature.png'
import Footer from './components/Footer'

const Home = () => {
  return (
    <>
      <div className='homepage-inner'>
        <img src={logoCrescent} className='App-logo' alt='logo' />
        <h1>Explaining the science. Personalizing the options. Making&nbsp;health decisions easier.</h1>

        <div className='inner_margin'>
          <Link to='/register' className='green_cta'>Already know you want Freckles? Sign up here.</Link>
          <p><span>Plenty of healthcare happens at home.</span> From sunburn to insomnia, coughs to constipation, even follow-up care after a visit to the doctor.</p>
          <p><span>But making informed decisions can be challenging.</span> It’s harder than it should be to get clear, unbiased information about what works or doesn’t, when to worry and when to stay the course—let alone personalized to your unique situation.</p>
          <p><span>Freckles is here to change that.</span> We explain what the science says, and what it means for you and your family. We’re a health startup powered by doctors and focused on people, and we are on a mission to help you make more informed decisions for yourself and your family.</p>
          <Link to='/about' className='green_cta'>Learn More about Us</Link>
          <h1>The Freckles Explainer</h1>
          <p>Sign up to get the Freckles Explainer free in your inbox each month. You’ll become more informed and more educated about treating common health conditions at home, with personalized, science-based content from medical experts—not marketers.</p>
          <Link to='/explainer' className='green_cta'>Tour the Explainer</Link>
        </div>
        <section id='letter'>
          <p>Hey Everyone—</p>
          <p>I’m John, Founder here at Freckles.</p>
          <p>As a pharmacist, I’ve spent my career answering people’s questions about their health: what to do or not do, take or not take, what’s normal and what’s yikes. Prescription questions, over-the-counter questions, non-drug questions, midnight texts from friends and family questions.</p>
          <p>We don’t all have a pharmacist in our family. We do all have questions about our health and our healthcare. And if it’s not a ‘go to the ER or call your doctor’ type of question, the most common place we turn is the internet.</p>
          <p>Ah the internet. Ask it a health question, and the “answers” you get back are at worst terrifying, and at best ... don’t really answer anything. Most health-related content is influenced by advertising and cluttered with sponsored content. It’s full of misinformation, contradictions and clickbait. Even the “good stuff” is generic and one-size-fits all, with no guidance on what it means for you and your unique situation.</p>
          <p>It’s harder than it should be to make empowered, informed choices about your health.</p>
          <p>And I want to change that.</p>
          <p>So I started Freckles, because I want to use my expertise as a pharmacist and passion for technology to make health information better for everyone.</p>
          <p>At Freckles: </p>
            <p class='at-freckles-list'>We believe health information should be as unique as you are — not one-size-fits-all.</p>
            <p class='at-freckles-list'>We believe health information should prioritize the people who use it — not advertisers.</p>
            <p class='at-freckles-list'>We believe health information should be based on science — not scare tactics.</p>
            <p class='at-freckles-list'>We believe health information should make your next step clearer — not leave you more overwhelmed.</p>
            <p  class='at-freckles-list'>And we believe everyone should have access to better health information — not be limited by barriers of income and information literacy.</p>
          <p>Freckles is here to do it differently. We are pioneering a new approach to health information publishing, one that is scientifically grounded and people-forward. No ads, no sponsors, no clickbait. Just quality content, based on the latest science, tailored to the needs and lives of our readers.</p>
          <p>We believe in what we’re doing and where we’re going. I hope you’ll join us on our journey, and I hope that our work helps you stay healthy on yours.</p>
          <p>Be well,</p>
          <img src={signature} className='App-logo' alt='logo' />
          <p>John Rose, PharmD, RPh</p>
          <p className='rm-mt'>Founder, Freckles Health</p>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default Home
