import React, { useState } from 'react'
import validator from 'email-validator'
import { useHistory } from 'react-router-dom'

import withAnimation from '../withAnimation'
import API from '../../api'

const EXISTING_USER = 'EXISTS'

const Email = ({ value, submitEmail, handleChange, fadeOutThen }) => {
  const isEmail = validator.validate(value)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const history = useHistory()

  const onSubmit = event => {
    event.preventDefault()
    setLoading(true)
    API.checkEmailOptions(value)
      .then((resp) => {
        if (resp.includes('password')) {
          // then the email already exists...this should display an error
          setError(EXISTING_USER)
          setLoading(false)
        } else {
          if (value && value.length > 0) {
            fadeOutThen(() => { submitEmail(value.trim()) })
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        setError('An unknown error occurred')
        console.log('err', err)
      })
  }

  const handleLoginClick = (e) => {
    e.preventDefault()
    history.push('/login')
  }

  const handleLocalChange = (e) => {
    setError()
    handleChange(e)
  }

  const isButtonDisabled = !isEmail || loading || error

  return (
    <form>
      <h1 className='light-font'>What's your email&nbsp;address?</h1>
      <p className='sub_copy'><em>Freckles is devoted to user privacy.<br />We don’t share or sell user info. No&nbsp;exceptions.</em></p>
      <input className='reduced_width' type='email' name='email' onChange={handleLocalChange} value={value} required autoFocus />

      {error === EXISTING_USER && (
        <>
          <small class='field-error'>This email has already been registered!</small><br />
          <a href='#' onClick={handleLoginClick}>Click here to log in or reset your password.</a><br />
        </>
      )}

      <button onClick={onSubmit} disabled={isButtonDisabled}>Next</button>
    </form>
  )
}

export default withAnimation(Email)
