import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { context as AuthContext } from '../../context/auth'

import useInboxItem from '../../hooks/useInboxItem'

import ExplainerView from './ExplainerView'
import MessageView from './MessageView'

export default () => {
  const { id } = useParams()
  const { user } = useContext(AuthContext)
  const { item } = useInboxItem(user, id) // inside this hook, the item is marked viewed
  console.log('inbox item', item)
  if (!item) {
    return <div>Loading</div>
  }

  if (item.type === 'explainer') {
    return <ExplainerView explainer={item.explainer} />
  } else {
    return <MessageView message={item} />
  }
}
