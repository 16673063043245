import React from 'react'
import withAnimation from '../withAnimation'

const LastName = ({ value, handleChange, submitLastName, fadeOutThen }) => {
  const onSubmit = event => {
    event.preventDefault()
    if (value && value.length > 0) {
      fadeOutThen(() => submitLastName(value.trim()))
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <h1 className='light-font'>And your last&nbsp;name?</h1>
      <input className='reduced_width' type='text' name='last_name' onChange={handleChange} value={value} required autoFocus />
      <button type='submit' disabled={value.length === 0}>Next</button>
    </form>
  )
}

export default withAnimation(LastName)
