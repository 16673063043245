import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import withAnimation from '../withAnimation'

const Medications = ({ value, onPrimary, name, handleChange, submitMedications, fadeOutThen }) => {
  const onSubmit = event => {
    event.preventDefault()
    fadeOutThen(() => submitMedications())
  }

  return (
    <form onSubmit={onSubmit}>
      {onPrimary && <h1 className='light-font'>Please tell us about any drugs, vitamins or supplements you take, and why.</h1>}
      {!onPrimary && <h1 className='light-font'>Please tell us about any drugs, vitamins or supplements {name} take, and why.</h1>}
      <TextareaAutosize name='medications' onChange={handleChange} value={value} autoFocus />
      <button type='submit'>Next</button>
    </form>
  )
}

export default withAnimation(Medications)
