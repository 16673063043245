import React, { createContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import PropTypes from 'prop-types'
import API from '../api'

const AuthContext = createContext()

let subscriptionDocRef

const AuthProvider = ({ children }) => {
  const [profile, setProfile] = useState()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()
  const [subscription, setSubscription] = useState()
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async authUser => {
      console.log('authUser updated', authUser)
      if (authUser) {
        if (authUser.isAnonymous === true) {
          setLoggedIn(false)
          setLoading(false)
        } else {
          // subscriptionDocRef = firebase.firestore().collection('user_subscription').doc(authUser.uid)
          // subscriptionDocRef.onSnapshot(docSnapshot => {
          //   // console.log(`Received doc snapshot: ${docSnapshot}`)
          //   setSubscription(docSnapshot.data())
          // }, err => {
          //   console.log(`Encountered error: ${err}`)
          // })

          setUser(authUser)
          setLoggedIn(true)
          const fetchedProfile = await API.getPrimaryProfile(authUser)
          if (fetchedProfile) {
            setProfile(fetchedProfile)
          }
          setLoading(false)
        }
      } else {
        setLoading(false)
        setUser(undefined)
      }
    })
    return () => {
      if (subscriptionDocRef) {
        // unmount the scription ref
        // TODO subscriptionDocRef.off()
      }
    }
  }, ['-'])

  const _logout = async () => {
    await firebase.auth().signOut()
    setUser(undefined)
  }

  const applyActionCode = (code) => {
    return firebase.auth().applyActionCode(code)
  }

  const sendVerifyEmail = () => {
    firebase.auth().currentUser.sendEmailVerification()
  }

  const completeRegistration = async () => {
    await firebase.firestore().collection('user_profile_private').doc(user.uid).set({ registrationComplete: true })
  }

  const sendResetPassword = (email) => {
    return firebase.auth().sendPasswordResetEmail(email)
  }

  const _login = async (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  }

  return (
    <AuthContext.Provider
      value={{
        profile,
        loading,
        loggedIn,
        user,
        subscription,
        completeRegistration,
        sendResetPassword,
        sendVerifyEmail,
        applyActionCode,
        login: _login,
        logout: _logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
const AuthConsumer = AuthContext.Consumer

AuthProvider.propTypes = {
  children: PropTypes.element
}

export const context = AuthContext

export { AuthProvider, AuthConsumer }
