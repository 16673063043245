import React from 'react'
import withAnimation from '../withAnimation'

const FirstName = ({ value, handleChange, submitFirstName, fadeOutThen }) => {
  const onSubmit = event => {
    event.preventDefault()
    if (value && value.length > 0) {
      window.sessionStorage.setItem('primaryUserFirstName', value)
      fadeOutThen(() => submitFirstName(value.trim()))
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <h1 className='light-font'>What's your first&nbsp;name?</h1>
      <input className='reduced_width' type='text' name='first_name' onChange={handleChange} value={value} required autoFocus />
      <button type='submit' disabled={value.length === 0}>Next</button>
    </form>
  )
}

export default withAnimation(FirstName)
