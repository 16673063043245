import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'

const useUserProfilesHook = (user) => {
  const [profiles, setProfiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const loadProfiles = async () => {
      setIsLoading(true)
      const data = await firebase.firestore()
        .collection(`user_profile_private/${user.uid}/profiles`)
        .get()
      const profiles = []

      if (data) {
        data.forEach(doc => {
          profiles.push(doc.data())
        })
      }

      setProfiles(profiles)
      setIsLoading(false)
    }
    if (user) {
      loadProfiles()
    }
  }, [user])

  return {
    isLoading,
    profiles
  }
}

export default useUserProfilesHook
