import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import withAnimation from './withAnimation'

import { context as AuthContext } from '../context/auth'

const FormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email')
})

const Reset = ({ history, fadeOutThen }) => {
  const { sendResetPassword } = useContext(AuthContext)
  const [success, setSuccess] = useState()

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: FormValidationSchema,
    onSubmit: async (values) => {
      sendResetPassword(values.email).then(() => {
        setSuccess(true)
        setTimeout(() => {
          history.push('/login')
        }, 3000)
      }).catch(err => {
        console.log(err)
        setSuccess(true)
        setTimeout(() => {
          history.push('/login')
        }, 3000)
      })
    }
  })

  return (
    <form>
      {success && (
        <>
          <h2>Please check your email for next steps.</h2>
        </>
      )}
      {!success && (
        <>
          <h1>Enter your email address for password reset instructions.</h1>
          <br />
          <h3 className='light-font'>Enter your email</h3>
          <input
            className='reduced_width'
            name='email'
            onChange={handleChange} // this looks odd but has purpose...formik marks fields "touched" on blur
            value={values.email}
            autofocus
          />
          {errors.email && touched.email && <small className='field-error'>{errors.email}</small>}
          <button onClick={handleSubmit} type='submit'>Reset Password</button>
          <p />
        </>
      )}
    </form>
  )
}

export default withAnimation(Reset)
