import React, { Component } from 'react'
import { Route, withRouter } from 'react-router-dom'
import _some from 'lodash.some'
// import _find from 'lodash.find'
import { AuthConsumer, AuthProvider } from './context/auth'

// import Header from './components/Header'
import About from './About'
import Explainer from './Explainer'
import FAQs from './FAQs'
import Home from './Home'
import Inbox from './components/inbox'
import Reset from './components/Reset'
// import InboxItemView from './components/inbox/InboxItem'
import Login from './components/Login'
import NavBar from './components/NavBar'
import Registration from './components/registration'
import Survey from './components/survey'

import EmailAction from './components/email-action'
import UserSample from './components/UserSample'
import ProfilesSample from './components/ProfilesSample'

class Freckles extends Component {
  constructor () {
    super()
    this.state = {
      name: '',
      newUser: {}
    }
  }

  render () {
    const recordName = name => this.setState({ name })
    const { pathname } = this.props.location
    const hideNavigation = _some(['/register', '/inbox', '/survey'], path => pathname.startsWith(path))
    const mainClasses = []

    if (pathname === '/') {
      mainClasses.push('homepage home')
    }

    if (pathname === '/explainer') {
      mainClasses.push('explainer home')
    }

    if (pathname === '/about') {
      mainClasses.push('about home')
    }

    if (pathname === '/faqs') {
      mainClasses.push('faqs home')
    }
    return (
      <AuthProvider history={this.props.history}>
        <AuthConsumer>
          {({ loading, loggedIn, user, profile }) => (
            <>
              {!hideNavigation && !user && <NavBar />}
              <main className={mainClasses.join(' ')}>
                {loading && <div>Loading</div>}
                {!loading && (
                  <>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/password-reset' component={Reset} />
                    <Route exact path='/about' component={About} />
                    <Route exact path='/explainer' component={Explainer} />
                    <Route exact path='/faqs' component={FAQs} />
                    <Route path='/register' render={(props) => (<Registration recordName={recordName} />)} />
                    <Route exact path='/user' render={(props) => (<UserSample history={this.props.history} />)} />
                    <Route exact path='/profiles' component={ProfilesSample} />
                    <Route path='/email-action' component={EmailAction} />
                  </>
                )}
                {!loading && user && user.emailVerified && <Route path='/survey' render={(props) => <Survey />} />}
                {!loading && user && !user.emailVerified && <Route path='/survey' render={(props) => <Survey />} />}
                {!loading && user && (
                  <>
                    <Route path='/inbox' render={(props) => <Inbox />} />
                  </>
                )}
              </main>
            </>
          )}
        </AuthConsumer>
      </AuthProvider>
    )
  }
}

export default withRouter(Freckles)
