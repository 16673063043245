import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Spring } from 'react-spring/renderprops'
import { context as AuthContext } from '../context/auth'

import close from '../images/close.svg'
import logo from '../images/logo_text.svg'
import hamburger from '../images/hamburger.svg'

const NavBarMain = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`

const HorizontalList = styled.ul`
    height: 100%;
    display: flex;
    flex-direction: row;

    li {
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        height: 100%;

        a {
            text-decoration: none;
        }
    }
`

const LogoContainer = styled.div`
    display: flex;
    height: 100%;
`

const SubscribeLi = styled.li`
    border-left: 2px solid;
`

const NavBar = () => {
  const { user, logout, loading } = useContext(AuthContext)
  const [mobileMenuOpen, toggleMobileMenu] = useState(false)

  const springFrom = {
    opacity: 0,
    marginTop: -100
  }
  const springTo = {
    opacity: 1,
    marginTop: 75
  }

  const handleToggleMobileMenu = () => {
    toggleMobileMenu(!mobileMenuOpen)
  }

  return (
    <>
      <NavBarMain className='navbar-container'>
        <LogoContainer><Link to='/' className='logo-container'><img src={logo} className='App-logo' alt='logo' /></Link></LogoContainer>
        <HorizontalList className='horz-list'>
          <li><Link to='/explainer' className='menu-item'>The Explainer</Link></li>
          <li><Link to='/about' className='menu-item'>About Freckles</Link></li>
          <li><Link to='/faqs' className='menu-item'>FAQs</Link></li>
          {loading && <li><Link to='/' className='menu-item'>....</Link></li>}
          {user && !loading && <li><Link className='menu-item' to='/inbox'>Inbox</Link></li>}
          {!user && !loading && <li><Link to='/login' className='menu-item'>Sign In</Link></li>}
          {user && !loading && <li><a className='menu-item' onClick={logout}>Sign Out</a></li>}
          <SubscribeLi className='subscribe '><Link to='/register' className='menu-item'>Sign Up</Link></SubscribeLi>
          <button onClick={handleToggleMobileMenu} id='hamburger' type='button'><img src={mobileMenuOpen ? close : hamburger} /></button>
        </HorizontalList>
      </NavBarMain>

      {mobileMenuOpen && (
        <Spring from={springFrom} to={springTo}>
          {props => (
            <nav id='mobile_nav' style={{ ...props }}>
              <Link onClick={handleToggleMobileMenu} to='/explainer' className='menu-item'>Freckles Explainer</Link>
              <Link onClick={handleToggleMobileMenu} to='/about' className='menu-item'>About Us</Link>
              <Link onClick={handleToggleMobileMenu} to='/faqs' className='menu-item'>FAQ's</Link>
              <Link onClick={handleToggleMobileMenu} to='/register' className='menu-item'>Sign Up</Link>
            </nav>
          )}
        </Spring>
      )}
    </>
  )
}

export default NavBar
