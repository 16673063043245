import React, { useContext, useEffect, useState } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { context as AuthContext } from '../../context/auth'

import Name from './Name'
import Birthday from './Birthday'
import Sex from './Sex'
// import Pronoun from './Pronoun'
import Conditions from './Conditions'
import Medications from './Medications'
import HomeCare from './HomeCare'
import MoreInfo from './MoreInfo'
import Welcome from './Welcome'
import Done from './Done'
import QuickNote from './QuickNote'

import API from '../../api'

// import Header from './components/Header'

const FormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Minimum 6 character length')
    .max(128, 'Maximum 128 character length')
    .matches(/[a-z]/, 'Missing a lowercase letter')
    .matches(/[A-Z]/, 'Missing an uppercase letter')
    .matches(/[*.!@#$%^&(){}[\]:;<>,.?/~_+-=|]/, 'Missing a special character')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  profileName: Yup.string()
    .min(2, 'Minimum 2 character length')
    .max(24, 'Maximum 24 character length'),
  birthdate: Yup.string()
    .required()
    .matches(/^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/, 'Please enter a valid date in the format mm/dd/yyyy')
})

const transitionDelay = 1

const Survey = ({ match, history, location, confirmRegistration }) => {
  const { user } = useContext(AuthContext)
  const [state, setState] = useState({
    completed: false,
    currentIndex: -1,
    transition: { opacity: 1 }
  })

  // for the first profile, profileName will be pre-filled and step skipped
  const [completeSteps, setCompleteSteps] = useState({ profileName: true })
  const [onPrimary, setOnPrimary] = useState(true)

  const { errors, values, handleChange, resetForm, setFieldValue } = useFormik({
    initialValues: {
      started: false,
      birthdate: '',
      conditions: '',
      homeCare: '',
      medications: '',
      moreInfo: '',
      pronouns: '',
      sex: '',
      profileName: window.sessionStorage.getItem('primaryUserFirstName') || ''
    },
    validationSchema: FormValidationSchema
  })

  window.sessionStorage.removeItem('primaryUserFirstName')

  const completeStep = (step) => {
    setCompleteSteps({
      ...completeSteps,
      [step]: true
    })
  }

  const { url } = match
  const { completed, currentIndex } = state

  // label isn't used for anyting here
  const forms = [
    { label: 'intro', path: '/', required: 'started' },
    { label: 'quicknote', path: '/privacy', required: 'privacy' },
    { label: 'profileName', path: '/name', required: 'profileName' },
    { label: 'birthdate', path: '/birthdate', required: 'birthdate' },
    { label: 'sex', path: '/sex', required: 'sex' },
    { label: 'conditions', path: '/conditions', required: 'conditions' },
    { label: 'medications', path: '/medications', required: 'medications' },
    { label: 'homeCare', path: '/home-care', required: 'homeCare' },
    { label: 'moreInfo', path: '/more-info', required: 'moreInfo' },
    { label: 'done', path: '/done', required: 'done' }
  ]

  // this effect will be called whenever a completedSteps value is changed
  useEffect((skipToEnd = false) => {
    const handleStateChange = async () => {
      window.scrollTo(0, 0) // make sure in view
      const completed = forms.length === (currentIndex + 1)

      // if (completed || skipToEnd) {
      //   const newUser = await API.register(values.email, values.password)
      //   setState({ ...state, newUser, completed: true })
      //   confirmRegistration(newUser)
      // } else {
      // Loop through the forms and find the next section that's incomplete
      let nextForm
      let nextIdx
      forms.forEach((form, idx) => {
        if (!nextForm && !completeSteps[form.required]) {
          nextForm = form
          nextIdx = idx
        }
      })

      if (!nextForm) {
        // COMPLETE...redirect where?
        // confirmRegistration(true)
        // if (!values.profileName) {
        //   const nameFromSession = window.sessionStorage.getItem('primaryUserFirstName')
        //   await API.addProfile(user, { ...values, profileName: nameFromSession, isPrimary: false })
        // } else {
        await API.addProfile(user, { ...values, isPrimary: onPrimary })
        // }

        if (values.done === 'new') {
          // reset everything to create another profile
          window.sessionStorage.removeItem('primaryUserFirstName')
          setOnPrimary(false)
          resetForm({ started: 'yup', profileName: ' ', privacy: 'done!' })
          setCompleteSteps({ started: true, privacy: true })
          history.push('/survey')
        } else {
          await API.completeRegistration(user)
          history.push('/inbox')
        }

        return
      }

      // set transition to true (changes styles to fade out with useSpring)
      setState({
        ...state,
        completed: completed || skipToEnd,
        currentIndex: nextIdx,
        transition: { opacity: 0 }
      })

      setTimeout(() => {
        // set transition to false (changes styles to fade out with useSpring)
        // and change route for next form field
        setState({
          ...state,
          transition: { opacity: 1 }
        })
        history.push(`/survey${nextForm.path}`)
      }, transitionDelay)
      // }
    }
    // this function runs everytime there is a change to completeSteps
    handleStateChange()
  }, [completeSteps])
  console.log('completed', completed)
  return (
    <>
      <Route exact path={`${url}`}>
        <Welcome completeStep={() => completeStep('started')} />
      </Route>
      <Route exact path={`${url}/privacy`}>
        <QuickNote completeStep={() => completeStep('privacy')} />
      </Route>
      <Route exact path={`${url}/name`}>
        <Name error={errors.profileName} onPrimary={onPrimary} value={values.profileName} submitName={() => completeStep('profileName')} handleChange={handleChange} />
      </Route>
      <Route exact path={`${url}/birthdate`}>
        <Birthday name={values.profileName} onPrimary={onPrimary} value={values.birthdate} error={errors.birthdate} handleChange={handleChange} submitBirthday={() => completeStep('birthdate')} />
      </Route>
      <Route exact path={`${url}/sex`}>
        <Sex name={values.profileName} onPrimary={onPrimary} submitSex={() => completeStep('sex')} setFieldValue={setFieldValue} />
      </Route>
      <Route exact path={`${url}/conditions`}>
        <Conditions name={values.profileName} onPrimary={onPrimary} value={values.conditions} submitConditions={() => completeStep('conditions')} handleChange={handleChange} />
      </Route>
      <Route exact path={`${url}/medications`}>
        <Medications name={values.profileName} onPrimary={onPrimary} value={values.medications} submitMedications={() => completeStep('medications')} handleChange={handleChange} />
      </Route>
      <Route exact path={`${url}/home-care`}>
        <HomeCare name={values.profileName} onPrimary={onPrimary} value={values.homeCare} submitHomeCare={() => completeStep('homeCare')} handleChange={handleChange} />
      </Route>
      <Route exact path={`${url}/more-info`}>
        <MoreInfo name={values.profileName} onPrimary={onPrimary} value={values.moreInfo} submitMoreInfo={() => completeStep('moreInfo')} handleChange={handleChange} />
      </Route>
      <Route exact path={`${url}/done`}>
        <Done name={values.profileName} onPrimary={onPrimary} submitDone={() => completeStep('done')} setFieldValue={setFieldValue} />
      </Route>
    </>
  )
}

export default withRouter(Survey)

/**
 *  value={values.email} submitEmail={() => completeStep('email')} handleChange={handleChange}
 *     const BirthdayForm = () =>
    const SexForm = () => <Sex submitSex={submitSex} transitioning={transitioning} />
    const PronounsForm = () => <Pronoun submitPronoun={submitPronoun} transitioning={transitioning} />
    const ConditionsForm = () => <Conditions submitConditions={submitConditions} />
    const MedicationsForm = () => <Medications submitMedications={submitMedications} />
    const HomeCareForm = () => <HomeCare submitHomeCareInfo={submitHomeCareInfo} />
    const MoreInfoForm = () => <MoreInfo submitMoreInfo={submitMoreInfo} />
    const questions = [
      Disclaimer,
      BirthdayForm,
      SexForm,
      PronounsForm,
      ConditionsForm,
      MedicationsForm,
      HomeCareForm,
      MoreInfoForm
    ]
 */
