import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import API from '../../api'
import Email from './Email'
// import Billing from './Billing'
import FirstName from './FirstName'
import LastName from './LastName'
import Password from './Password'
// import Subscription from './Subscription'
import Welcome from './Welcome'

import { context as AuthContext } from '../../context/auth'
// import Header from './components/Header'

const FormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(12, '12 characters minimum')
    .max(128, '128 character maximum')
    // .matches(/[a-z]/, 'Missing a lowercase letter')
    // .matches(/[A-Z]/, 'Missing an uppercase letter')
    // .matches(/[*.!@#$%^&(){}[\]:;<>,.?/~_+-=|]/, 'Missing a special character')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

// const first = true

const Registration = ({ match, history, location, confirmRegistration }) => {
  const { user } = useContext(AuthContext)

  const [state, setState] = useState({
    completed: false,
    currentIndex: -1
    // transition: { opacity: 1 }
  })

  const [completeSteps, setCompleteSteps] = useState({})

  const { values, errors, touched, handleChange, handleBlur } = useFormik({
    initialValues: {
      // started: '',
      email: '',
      first_name: '',
      last_name: '',
      newUser: {},
      password: '',
      confirm_password: ''
      // billing: '',
      // plan: ''
    },
    validationSchema: FormValidationSchema
  })

  const saveUser = useCallback((user) => {
    setState({ ...state, user })
  }, [state])

  const { url } = match
  const { currentIndex } = state

  // label isn't used for anyting here
  const forms = [
    // { label: 'intro', path: '', required: 'started' },
    { label: 'email', path: '/email', required: 'email' },
    { label: 'fname', path: '/first', required: 'first_name' },
    { label: 'lname', path: '/last', required: 'last_name' },
    { label: 'pass', path: '/password', required: 'password' },
    // { label: 'subs', path: '/subscription', required: 'plan' },
    // { label: 'billing', path: '/billing', required: 'billing' },
    { label: 'complete', path: '/complete', required: 'complete' }
  ]

  const fadeOutThen = fn => fn()

  const completeAndNavigate = (step, navTo) => {
    setCompleteSteps({
      ...completeSteps,
      [step]: true
    })
    history.push(navTo)
  }

  const completeStart = () => {
    window.scrollTo(0, 0) // make sure in view
    completeAndNavigate('started', '/register/email')
  }

  const completeEmail = () => {
    window.scrollTo(0, 0) // make sure in view
    completeAndNavigate('email', '/register/first')
  }

  const completeFirst = () => {
    window.scrollTo(0, 0) // make sure in view
    completeAndNavigate('first_name', '/register/last')
  }

  const completeLast = () => {
    window.scrollTo(0, 0) // make sure in view
    completeAndNavigate('last_name', '/register/password')
  }

  const completePass = useCallback(async () => {
    console.log('user in completePass', user)
    window.scrollTo(0, 0) // make sure in view

    // const newUser = await API.register(values.email, values.password)
    // console.log('register complete')
    // setState({ ...state, newUser })
    // mark registration complete in profile
    // await API.completeRegistration(user)
    // console.log('API completeRegistration complete')
    // redirect to inbox

    completeAndNavigate('password', '/register/complete')
  }, [user])

  return (
    <>
      <Route exact path={`${url}`}>
        <Welcome fadeOutThen={fadeOutThen} completeStep={() => completeStart()} />
      </Route>
      <Route exact path={`${url}/email`}>
        <Email fadeOutThen={fadeOutThen} value={values.email} submitEmail={() => completeEmail()} handleChange={handleChange} />
      </Route>
      <Route exact path={`${url}/first`}>
        <FirstName fadeOutThen={fadeOutThen} value={values.first_name} handleChange={handleChange} submitFirstName={() => completeFirst()} />
      </Route>
      <Route exact path={`${url}/last`}>
        <LastName fadeOutThen={fadeOutThen} value={values.last_name} handleChange={handleChange} submitLastName={() => completeLast()} />
      </Route>
      <Route exact path={`${url}/password`}>
        <Password fadeOutThen={fadeOutThen} values={values} touched={touched} errors={errors} saveUser={saveUser} handleBlur={handleBlur} handleChange={handleChange} submitPassword={() => completePass()} />
      </Route>
      {/* <Route exact path={`${url}/subscription`}>
        <Subscription handleChange={handleChange} submitSubscription={submitSubscription} />
      </Route> */}
      {/* <Route exact path={`${url}/billing`}>
        <Billing submitBilling={() => completeStep('billing')} />
      </Route> */}
      <Route exact path={`${url}/complete`}>
        <form>
          <h1>That was easy!</h1>
          <p className='sub_copy'>Your Freckles library awaits! Check your email to confirm your address for instant access.</p>
          {/* <button onClick={() => history.push('/survey')}>Continue</button> */}
        </form>
      </Route>
    </>
  )
}

export default withRouter(Registration)
