import { Element, Link } from 'react-scroll'
import Footer from './components/Footer'

const FAQs = () => {
  const questionsAnswers = [
    {
      question: 'What is the Explainer?',
      answer: 'The Freckles Explainer is a resource to help you make more informed choices about your healthcare. Each month, we publish a new Explainer on a different topic, outlining what’s happening in the body, various approaches to treatment, and what the science says. Paid subscribers also receive a personalized assessment of treatment options.'
    },
    {
      question: 'How do I get the Explainer?',
      answer: 'You’ll receive an email each time a new issue is available. Follow the link in the email to access the Explainer (and your back issues) within your Freckles Library.'
    },
    {
      question: 'How much does the Explainer cost?',
      answer: 'The basic Explainer is free, or upgrade for $5/month to receive a personalized assessment of treatment options for yourself and for loved ones in your care. Either way, you need to sign up for an account.'
    },
    {
      question: 'Who writes the Explainer?',
      answer: 'All information is sourced by our network of Freckles Research Partners. These are medical experts including doctors, pharmacists, academics and other health professionals. Our content team translates their findings into plain English, and a Research Partner reviews it again to validate the science before it goes out the door.'
    },
    {
      question: 'How does personalization work?',
      answer: 'Factors like age, sex, health history, medications and more can have a very big impact on whether a specific treatment option is safe and effective. As a paid subscriber, you’ll have the opportunity to share as much (or little) of your health information with us as you like. Our Research Partners factor this in to help you assess your best bets, good shots, and no-gos when it comes to treatment options for your unique situation.'
    },
    {
      question: 'Why should I trust you with my info?',
      answer: "Privacy and data security are fundamental values of Freckles as an organization. As outlined in our <a href='#' class='green_cta'>Privacy Policy</a>, we keep your data under lock and key. It will only be used to personalize and deliver the Explainer to you each month. It will never be shared or sold or used for any type of advertising."
    },
    {
      question: 'What if I can’t afford a paid subscription?',
      answer: "We don’t want money being the reason someone doesn’t have access to personalized health info through Freckles. If you can’t afford it, drop us a line at <a href='mailto:support@freckleshealth.com' class='green_cta'>support@freckleshealth.com.</a> We abide by the honor system."
    },
    {
      question: 'What if I take care of kids / parents / spouse / another loved one?',
      answer: 'We’ve got you covered. The paid subscription allows you to personalize The Explainer for multiple people, so each of your unique health needs can be addressed.'
    },
    {
      question: 'Do drug companies pay you to mention their products?',
      answer: 'Absolutely not. Our funding comes from individual people who sign up for Freckles, and from employers and other organizations who provide Freckles to their members. We don’t do ads, sponsored content, or sponsor-influenced recommendations. The information and treatment options we present are 100% determined by the science, in service of our readers.'
    }
  ]

  const idFormat = (term = '') => term.trim().replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()
  const getMarkup = (htmlStr = '') => `<p>${htmlStr}</p>`

  return (
    <>
      <div className='faqs-inner'>
        <div className='inner_margin'>
          <h1>FAQs.</h1>

          <nav id='faqs-nav'>
            {questionsAnswers.map(({ question }, idx) =>
              <div key={`question-${idx}`}>
                <Link key={idFormat(question)} activeClass='active' to={idFormat(question)} offset={-150} smooth className='purple_cta'>{question}</Link>
                <br />
              </div>
            )}
          </nav>

          <hr class='freckles_gradient' />

          {questionsAnswers.map(({ question, answer }) =>
            <Element key={idFormat(question)} name={idFormat(question)} className='question_full'>
              <p><span>{question.trim()}</span></p>
              <div dangerouslySetInnerHTML={{ __html: getMarkup(answer) }} />
            </Element>
          )}
        </div>
      </div>

      <Footer />
    </>
  )
}

export default FAQs
